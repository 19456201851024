<template>
  <div>
    <h1>Hello Iliass</h1>
    <h1>{{ media }}</h1>

    <qrcode-stream
      :constraints="{ deviceId: selectedDevice.deviceId }"
      :track="trackFunctionSelected.value"
      :formats="selectedBarcodeFormats"
      @error="onError"
      @detect="onDetect"
      v-if="selectedDevice !== null"
      ></qrcode-stream>
      <p
      v-else
      class="error"
      >
      No cameras on this device
  </p>

  </div>
</template>

<script setup>
import { QrcodeStream } from 'vue-qrcode-reader'
import {ref,computed,onMounted, defineEmits} from "vue";

const media = ref('')
const audiourl = ref('')
const emit = defineEmits(['update:visible',"detect"])
const togglePopUp = (value) => { 
    emit("update:visible",value)
 }
function paintOutline(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = 'red'

    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
  }
}
function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 2
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}
function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 3
    ctx.strokeStyle = '#35495e'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
  }
}
const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[1])

const selectedDevice = ref(null)
const devices = ref([])

onMounted(async () => {
  if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
      ({ kind }) => kind === 'videoinput'
      )
      media.value = JSON.stringify(devices.value)

    if (devices.value.length > 0) {
      const phoneCamera = devices.value.find(({ label }) =>
        label.toLowerCase().includes('back') || label.toLowerCase().includes('rear')
      )
      selectedDevice.value = phoneCamera || devices.value[0]
    }
  } else {
    console.error('MediaDevices API not supported')
  }
})

const result = ref('')

function onDetect(detectedCodes) {
    const numbersonly = detectedCodes.map((code) => code.rawValue.replace(/[^0-9]/g, '')).join(', ');
    console.log(numbersonly)
    emit("detect",numbersonly);
    togglePopUp(false);
    var audio = new Audio(audiourl.value);
    audio.play();
    result.value = numbersonly;
}
const barcodeFormats = ref({
  qr_code: true,
})
const selectedBarcodeFormats = computed(() => {
    return Object.keys(barcodeFormats.value || {}).filter((format) => barcodeFormats.value && barcodeFormats.value[format]);
})

const error = ref('')

function onError(err) {
  if (err.name === 'NotAllowedError') {
    error.value += 'you need to grant camera access permission'
  } else if (err.name === 'NotFoundError') {
    error.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
    error.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
    error.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
    error.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
    error.value +=
      'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
    error.value += err.message
  }
}

</script>

